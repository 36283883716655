import { gql } from '@apollo/client';


export const STORE_ENV_DATA_STRING = `
    mutation storeEnvData(
        $period: String!
        $partnerName: String
        $managerId: String
        $abGroup: String
        $device_adid: String
        $device_platform: deviceType
        $referrer_id: String
        $participantId: String
    ) {
        storeEnvData(
            period: $period,
            partnerName: $partnerName,
            managerId: $managerId,
            abGroup: $abGroup,
            device_adid: $device_adid,
            device_platform: $device_platform,
            referrer_id: $referrer_id,
            participantId: $participantId
        ) {
            success
        }
    }
`;

export const STORE_ENV_DATA = gql`${STORE_ENV_DATA_STRING}`;


export default {
    STORE_ENV_DATA,
    STORE_ENV_DATA_STRING,
};
