/* eslint-disable react/jsx-props-no-spreading */
/* LIB */
import React, { Suspense, useContext } from 'react';
import { useMutation, useQuery, useSuspenseQuery } from '@apollo/client';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Raven from 'raven-js';
import queryString from 'query-string';
import debounce from 'lodash/debounce';
import compose from 'lodash/flowRight';
import omit from 'lodash/omit';
import { analyticService } from 'global/services';
/* UTILS */
import {
    parseCategories, parseDishes, parseMenuType, parseTags,
} from 'app/utils/basketSchema';
import { getDishesFromBasketSections } from 'app/utils/dish';
import { getHiddenElements } from 'app/utils/url';
import { isDesktop } from 'app/utils/resolution';
import { getReferrerCodeFromLocation, saveReferrerCode } from 'app/utils/referrer';
import { getTrialScenarioFromUrl, SCENARIO_5X2, SCENARIO_3X2 } from 'app/utils/trial-scenario';
import { SUBSCRIPTION_TYPES } from 'app/const/subscription';
import { BASKET_FILLED_STATUS } from 'app/graphql/client/basket';
/* CONTEXT */
import withBillingParams from 'app/containers/withBillingParams';
import MenuDishesProvider from 'app/containers/MenuDishesProvider';
import withDeliveryBasket from 'app/containers/withDeliveryBasket';
import MenuDatesProvider from 'app/containers/contexts/menuDates.context';
import { AuthContext } from 'app/containers/AuthContainer';
import { DishesSelectionProvider } from 'app/containers/contexts/dishesSelection.context';
import withABTestData from 'app/containers/contexts/abTest.context';
import { Blur, Overlay } from 'app/components/BlurredOverlay';
import { LoaderProvider } from 'app/containers/contexts/loader.context';
import { OVERLAY_ANIMATION_TIME } from 'app/containers/contexts/overlay.context';
import { AvailableLinksProvider } from 'app/containers/contexts/availableLinks.context';
import { promocodeVar, isBasketFilledVar, subscriptionTypeVar } from 'app/apollo/reaction';
import {
    MenuProvider, withMenuDispatch, TOP, STORED_POSITION,
} from 'app/containers/contexts/menu.context';
/* NETWORK */
import USER_QUERY from 'app/graphql/network/auth/userQuery.graphql';
import {
    ADD_ITEMS_TO_CART_BY_ID_LIST,
    ADD_ITEMS_TO_CART_BY_SCHEMA,
} from 'app/graphql/network/basket/basketMutations';
import { mainConnect as connect } from 'app/connect';
/* COMPONENTS */
import DesinfectionInfoPopup from 'app/components/infoPopup/DesinfectionInfoPopup';
import Header from 'app/views/Header';
import { MenuSkeletonPage } from 'app/components/Skeletons/MenuPageSkeletons';
import { useDishDetails } from 'app/components/MobileDishDetails';
import { FiltersContext } from 'app/containers/contexts/filters.context/filters.context';
import Menu from '../Menu';
import Basket from '../Basket';
import CheckoutContainer from '../Checkout/CheckoutContainer';
import MobileFilters from '../MobileFiltersNew';
import MobileDishDetails from '../MobileDishDetails';
import { MainPopups } from './MainPopups';
/* */
import { pickPeriodReaction } from '../../apollo/reactions/filters';

const DishDetails = React.lazy(() => import('../DishDetails'));
const Thanks = React.lazy(() => import('../Thanks'));
const Auth = React.lazy(() => import('../Auth'));
const Profile = React.lazy(() => import('../Profile'));
const DeliveryMenu = React.lazy(() => import('../DeliveryMenu'));
const DeliveryBasket = React.lazy(() => import('../DeliveryBasket'));

// import {
//     DeliveryDateDialogContainer,
//     DateTimeConfirmDialogContainer,
//     AddressConfirmDialogContainer,
// } from 'app/components/Dialog';
// import {
//     MobilePortionsSelectContainer,
//     MobileTimeSelectContainer,
// } from 'app/components/Select';
// import OverlaysContainer from 'app/components/OverlaysContainer';
// import PushNotificationsPopup from 'app/components/PushNotificationsPopup';
// import MobileStreet from '../MobileStreet';
// import MainPopUpAndDialog from './MainPopUpAndDialog';

// TODO: перенести в utils
const getDelivery = ({ deliveries, deliveryId }) => deliveries.find(({ id }) => id === deliveryId);

const getDishDetailsPathname = (deliveryId) => (
    deliveryId ? `/app/delivery/${deliveryId}/dish/` : '/menu/dish/'
);
const differentAnimations = isDesktop() ? 'right' : 'bottom';

class Main extends React.Component {
    static getDerivedStateFromProps(props, state) {
        const {
            match: { params: { dishId } },
            showBasket,
            showCheckout,
            // showMobileStreet, /* deprecated BR-1112 */
            // showProfile,  /* deprecated BR-1112 */
            showDeliveryBasket,
        } = props;
        const { selectedDish } = state;

        const nextState = {
            hiddenElements: getHiddenElements(),
        };

        /*
            Значения устанавливаются в state, когда происходит навигация на определенный url
            удаляется из стейта после окончания анимации скрытия через callback onExited
        */

        if (dishId && selectedDish !== dishId) {
            nextState.selectedDish = dishId;
        }
        /*
            TODO: неудоачное решение с showBasket и showCheckout
            Они становятся true одновременно для компонента, и анимация в transition не происходит правильно.
            Правильным было бы рендерить один оверлей для всех элементов и иметь одно условие для блюринга основного контента,
            а рендерингом корзины управлять только через Transition.
        */
        if (showBasket) {
            nextState.showBasket = true;
            // } else if (showCheckout && showMobileStreet) {
            //     nextState.showCheckout = true;
            //     nextState.showMobileStreet = true;
            // } else if (showProfile && showMobileStreet) {
            //     nextState.showMobileStreet = true;
        } else if (showCheckout) {
            nextState.showCheckout = true;
        } else if (showDeliveryBasket) {
            nextState.showDeliveryBasket = true;
        }

        return nextState;
    }

    isBasketFillingTracked = false;

    state = {
        isMobileNavigationOpen: false,
        selectedDish: null,
        showBasket: false,
        showCheckout: false,
        // showMobileStreet: false,
        // allowRenderMobileStreet: false,

        showMobileFilters: false,
        showMobileFiltersOverlay: false,
        isMobileFiltersApplying: false,

        showMobilePortions: false,
        mobilePortionsInfo: {},
        showMobileTimeIntervals: false,
        mobileTimeIntervalsInfo: {},
        // mobileStreetInfo: {},
        showMobileDishDetails: false,
        showMobileDishDetailsOverlay: false,
        mobileDishDetailsInfo: {},
        showDeliveryBasket: false,
        animateEnterOverlay: false,
        animateExitOverlay: false,
        hiddenElements: {},

        showFilterChangedAnimation: false,

        openedModal: 'default',
        showModalOverlay: false,

        showDesinfectionInfoPopupOverlay: false,
        showDesinfectionInfoPopup: false,

        dishDetailsPathname: '',

        isDishesUpdating: false,
        /*
            NOTE: temporaryBasketPeriod используется для хранения периода в момент нахождения в корзине/чекауте.
            Иначе, при клике по баннерам в слайдере в меню при скрытом из фильтров периоде
            "доставка завтра" превращается в "доставку в понедельник"
        /*                */
        temporaryBasketPeriod: false,

        // showDateTimeConfirmDialog: false, /* deprecated BR-1112 */
        isDateTimeConfirmDialogExtraText: false,
        dateTimeConfirmDialogType: null,
        popUpUiState: 'defalut',

        isBasketFillingInProcess: false,
    };

    handleResize = debounce(() => {
        const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        // eslint-disable-next-line react/no-unused-state
        this.setState({ width }); // just to trigger re-render
    }, 300);

    componentDidMount() {
        const {
            location,
            showFilters,
            match: { params: { deliveryId } },
        } = this.props;

        window.addEventListener('resize', this.handleResize);

        saveReferrerCode(getReferrerCodeFromLocation(location));

        if (showFilters) {
            this.setState({
                showMobileFilters: true,
                showMobileFiltersOverlay: true,
            });
        } else {
            this.setState({
                showMobileFilters: false,
                showMobileFiltersOverlay: false,
            });
        }

        const dishDetailsPathname = getDishDetailsPathname(deliveryId);
        this.setState({ dishDetailsPathname });

        this.resolveModalVisibility();
        this.resolveDesinfectionInfoPopupVisibility();
    }

    async componentDidUpdate(prevProps, prevState) {
        const {
            showMenu,
            match: { params: { deliveryId } },
            basketQuery: {
                error: basketError,
                cart,
            },
            selectedFilters: { prevSelectedPeriods },
        } = this.props;

        const { temporaryBasketPeriod } = this.state;
        const { temporaryBasketPeriod: prevTemporaryBasketPeriod } = prevState;

        if (showMenu && temporaryBasketPeriod && prevTemporaryBasketPeriod) {
            // при возврате в меню из баскета/чекауте/профиля сбрасывается временный период,
            // который используется только в корзине и возвращается предыдущий
            const prevSelectedPeriod = prevSelectedPeriods[0];
            this.setTemporaryBasketPeriod(false);
            this.handleSetPeriod(prevSelectedPeriod);
        }

        this.resolvePaymentMethodByPeriod(prevProps);

        await this.parseSchemaFromUrl();

        this.resolveMobileFiltersVisibility();
        this.resolveModalVisibility();
        this.resolveDesinfectionInfoPopupVisibility();


        // TODO: перенести в getDerivedStateFromProps
        const prevDishDetailsPathname = prevState.dishDetailsPathname;
        const dishDetailsPathname = getDishDetailsPathname(deliveryId);

        if (dishDetailsPathname !== prevDishDetailsPathname) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ dishDetailsPathname });
        }

        if (cart && cart.typeOfSet) {
            this.trackBasketFilling(cart.typeOfSet);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    /*
        Отключение рекуррента в новогодний период
    */
    // eslint-disable-next-line react/sort-comp
    async resolvePaymentMethodByPeriod(prevProps) {
        const {
            filterQuery: { menuFilter },
            selectedFilters: { selectedPeriod: currentSelectedPeriod },
            updateBilling,
        } = this.props;

        const {
            selectedFilters: { selectedPeriod: prevSelectedPeriod },
        } = prevProps;

        const isSelectedPeriodChanged = prevSelectedPeriod !== currentSelectedPeriod;

        if (isSelectedPeriodChanged) {
            const period = menuFilter.periods.find((p) => p.start === currentSelectedPeriod);
            if (period?.defaultPaymentMethod === 'online') {
                await subscriptionTypeVar('singlePay');
                await updateBilling();
            }
        }
    }

    // eslint-disable-next-line react/sort-comp
    resolveMobileFiltersVisibility() {
        const { showFilters } = this.props;
        const { showMobileFilters } = this.state;

        if (!showFilters && showMobileFilters) {
            this.setState({
                showMobileFilters: false,
                showMobileFiltersOverlay: false,
            });
        }
    }

    // eslint-disable-next-line react/sort-comp
    setDishesUpdatingState = (state, callback) => {
        this.setState(
            { isDishesUpdating: state },
            callback,
        );
    };

    setTemporaryBasketPeriod = (state) => {
        this.setState({ temporaryBasketPeriod: state });
    };

    resolveModalVisibility() {
        const { location: { search } } = this.props;
        const { showModalOverlay } = this.state;

        const { modal } = queryString.parse(search);

        if (modal && !showModalOverlay) {
            this.setState({
                openedModal: modal,
                showModalOverlay: true,
            });
        } else if (!modal && showModalOverlay) {
            this.setState({
                showModalOverlay: false,
            });
            setTimeout(() => {
                this.setState({
                    openedModal: 'default',
                });
            }, OVERLAY_ANIMATION_TIME);
        }
    }

    handleDishRemovedFromBasket = () => {
        const { menuDispatchContext } = this.props;

        menuDispatchContext({
            type: 'scrollTo',
            payload: { target: TOP },
        });
    };

    resolveDesinfectionInfoPopupVisibility() {
        const { location: { search } } = this.props;
        const { showDesinfectionInfoPopupOverlay } = this.state;

        const { modal } = queryString.parse(search);

        if (modal && modal === 'desinfection-info' && !showDesinfectionInfoPopupOverlay) {
            this.setState({
                showDesinfectionInfoPopup: true,
                showDesinfectionInfoPopupOverlay: true,
            });
        } else if ((!modal || modal !== 'desinfection-info') && showDesinfectionInfoPopupOverlay) {
            this.setState({
                showDesinfectionInfoPopupOverlay: false,
            });
            setTimeout(() => {
                this.setState({
                    showDesinfectionInfoPopup: false,
                });
            }, OVERLAY_ANIMATION_TIME);
        }
    }

    handleToggleMobileNavigation = () => {
        const { isMobileNavigationOpen } = this.state;
        analyticService.push({ eventName: isMobileNavigationOpen ? 'Burger_Menu_Close' : 'Burger_Menu_Open' });
        this.setState({
            isMobileNavigationOpen: !isMobileNavigationOpen,
        });
    };

    disableEnterOverlayAnimation = () => {
        this.setState({
            animateEnterOverlay: false,
        });
    };

    handleOpenBasket = () => {
        const {
            history,
            location: { search },
            menuDispatchContext,
        } = this.props;
        menuDispatchContext({
            type: 'scrollTo',
            payload: { target: STORED_POSITION, value: window.scrollY },
        });

        analyticService.push({ eventName: 'Go_to_Cart' });

        this.setState({
            animateEnterOverlay: true,
        }, () => {
            history.push(`/basket/${search}`);
        });
    };

    handleCloseBasket = () => {
        const { history, location: { search } } = this.props;

        this.setState({
            animateExitOverlay: true,
        }, () => {
            history.push({
                pathname: '/menu/',
                search,
            });
        });
    };

    // eslint-disable-next-line class-methods-use-this
    handleSetPeriod = async (periodStart, skipSavingToPrev) => {
        pickPeriodReaction({ periodStart, skipSavingToPrev });
    };

    onBasketClosed = () => {
        this.setState({
            showBasket: false,
            animateExitOverlay: false,
        });
    };

    handleOpenDeliveryBasket = async (e, deliveryId, period) => {
        const {
            history,
            location: { search },
            match: { params: { deliveryId: matchedDeliveryId } },
            deliveryBasketQuery,
            setDeliveryBasketIsLoading,
        } = this.props;

        setDeliveryBasketIsLoading(true);

        this.setState({
            animateEnterOverlay: true,
        }, async () => {
            const id = deliveryId || matchedDeliveryId;

            const searchParams = queryString.parse(search);

            // TODO: не передавать период для доставок в прошлом
            if (period) {
                pickPeriodReaction({ periodStart: period });
                searchParams.period = period;
            }

            const nextSearch = queryString.stringify(searchParams);

            const nextUrl = `/app/delivery/${id}/basket/?${nextSearch}`;

            history.push(nextUrl);

            if (deliveryId) {
                await deliveryBasketQuery.refetch({
                    deliveryId,
                    clear: true,
                }, 'network-only');
            }
        });
    };

    handleCloseDeliveryBasket = ({ deliveryId, navigateToDeliveryMenu }) => {
        const {
            history,
            location: { search },
            setDeliveryBasketIsLoading,
        } = this.props;

        setDeliveryBasketIsLoading(false);
        this.setState({
            animateExitOverlay: true,
        }, () => {
            if (navigateToDeliveryMenu) {
                history.push(`/app/delivery/${deliveryId}/${search}`);
            } else {
                /**
                 * Параметр delivery используется в ЛК в хуке useDeliveryIdParam
                 * для скролла к нужной доставке
                 */
                const params = queryString.parse(search);
                params.delivery = deliveryId;
                const nextSearch = queryString.stringify(params);

                history.push({
                    pathname: '/profile/',
                    search: nextSearch,
                });
            }
        });
    };

    onDeliveryBasketClosed = () => {
        this.setState({
            showDeliveryBasket: false,
            animateExitOverlay: false,
        });
    };

    handleOpenDishDetails = (path) => {
        const { history, location: { pathname, search } } = this.props;

        this.setState(
            { animateEnterOverlay: true },
            () => {
                this.dishDetailsOpenFrom = pathname;
                history.push(`${path}${search}`);
            },
        );
    };

    handleCloseDishDetails = () => {
        const {
            history,
            location: { search },
            match: { params: { deliveryId } },
        } = this.props;

        const navigate = () => {
            const defaultPathname = deliveryId
                ? `/app/delivery/${deliveryId}/basket/`
                : '/menu/';

            const pathname = this.dishDetailsOpenFrom || defaultPathname;
            history.push({ pathname, search });
        };

        this.setState(
            { animateExitOverlay: true },
            navigate,
        );
    };

    onDishDetailsClosed = () => {
        this.setState({
            selectedDish: null,
            animateExitOverlay: false,
        });
    };

    handleOpenCheckout = () => {
        const { history, location: { search } } = this.props;
        this.setState({
            animateEnterOverlay: true,
        }, () => {
            history.push(`/checkout/${search}`);
        });
    };

    onCheckoutEntered = () => {
        this.disableEnterOverlayAnimation();
        // this.setState({ showBasket: false });
    };

    handleCloseCheckout = () => {
        const { history, location: { search } } = this.props;
        this.setState({
            animateExitOverlay: true,
        }, () => {
            history.push(`/basket/${search}`);
        });
    };

    onCheckoutClosed = () => {
        this.setState({
            showCheckout: false,
            animateExitOverlay: false,
        });
    };

    handleOpenMobileFilters = () => {
        const {
            // Data
            history,
            location: { search },
            match: {
                params: { deliveryId },
            },
        } = this.props;

        const nextPath = deliveryId ? `/app/delivery/${deliveryId}/filters/${search}` : `/menu/filters/${search}`;

        history.push(nextPath);

        analyticService.push({
            eventName: 'Change_Filter_Item',
            itemKey: 'open',
        });

        this.setState({
            showMobileFilters: true,
            showMobileFiltersOverlay: true,
        });
    };

    handleCloseMobileFilters = (e, { isChangesApplied } = { isChangesApplied: false }) => {
        const {
            history,
            location: { search },
            match: {
                params: { deliveryId },
            },
        } = this.props;

        if (isChangesApplied) {
            window.scrollTo(0, 0);
        }
        this.setState({
            showMobileFiltersOverlay: false,
            showFilterChangedAnimation: isChangesApplied,
            isDishesUpdating: isChangesApplied,
        });
        setTimeout(() => {
            this.setState({
                showMobileFilters: false,
                isDishesUpdating: false,
                isMobileFiltersApplying: false,
            });
            const nextPath = deliveryId ? `/app/delivery/${deliveryId}/${search}` : `/menu/${search}`;
            history.push(nextPath);
            if (isChangesApplied) {
                setTimeout(() => {
                    this.setState({ showFilterChangedAnimation: false });
                }, 500);
            }
        }, OVERLAY_ANIMATION_TIME);
    };

    handleMobileFiltersStartApplying = () => {
        this.setState({
            isMobileFiltersApplying: true,
        });
    };

    handleOpenMobilePortions = (info) => {
        this.setState({
            showMobilePortions: true,
            mobilePortionsInfo: info,
        });
    };

    handleCloseMobilePortions = () => {
        this.setState({
            showMobilePortions: false,
            mobilePortionsInfo: {},
        });
        setTimeout(
            () => this.setState({ mobilePortionsInfo: {} }),
            OVERLAY_ANIMATION_TIME,
        );
    };

    /* deprecated BR-1112 */
    // handleOpenMobileTimeIntervals = (info) => {
    //     this.setState({
    //         showMobileTimeIntervals: true,
    //         mobileTimeIntervalsInfo: info,
    //     });
    // };

    /* deprecated BR-1112 */
    // handleCloseMobileTimeIntervals = () => {
    //     this.setState({
    //         showMobileTimeIntervals: false,
    //     });
    //     setTimeout(() => (
    //         this.setState({
    //             mobileTimeIntervalsInfo: {},
    //         })
    //     ), OVERLAY_ANIMATION_TIME);
    // };

    handleOpenModal = (e, modalType) => {
        const {
            history,
            location: { pathname, search },
        } = this.props;

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        history.push(`${pathname}${search}&modal=${modalType}`);
    };

    handleOpenDeliveryPricePopup = (e, context = 'default') => {
        analyticService.push({ eventName: 'Check_Info_About_Delivery' });
        this.handleOpenModal(e, 'delivery-info');
    };

    handleOpenSubscriptionInfoPopup = (e, context = 'default') => {
        this.setState({ popUpUiState: context });
        this.handleOpenModal(e, 'recurrent-info');
    };

    handleOpenAddressInfoPopup = (e, context = 'default') => {
        this.setState({ popUpUiState: context });
        this.handleOpenModal(e, 'address-info');
    };

    handleOpenDesinfectionInfoPopup = (e) => {
        const {
            history,
            location: { pathname, search },
        } = this.props;

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        history.push(`${pathname}${search}&modal=desinfection-info`);
    };

    handleCloseDesinfectionInfoPopup = () => {
        const {
            history,
            location: { pathname, search },
        } = this.props;

        const { modal, ...otherSearchParams } = queryString.parse(search);
        const nextSearch = queryString.stringify(otherSearchParams);

        history.push(`${pathname}?${nextSearch}`);
    };

    /* deprecated BR-1112 */
    // Profile address dialogs
    // handleOpenAddressConfirmDialog = ({ multiSubscriptions, confirm, reject }) => {
    //     const { showAddressConfirmDialog } = this.state;
    //     if (showAddressConfirmDialog) return;

    //     this.setState({
    //         showAddressConfirmDialog: true,
    //         isAddressConfirmDialogExtraText: multiSubscriptions,
    //         confirm,
    //         reject,
    //     });
    // };

    /* deprecated BR-1112 */
    // handleAddressConfirmDialogClose = () => {
    //     this.setState({
    //         showAddressConfirmDialog: false,
    //         isAddressConfirmDialogExtraText: null,
    //         confirm: null,
    //     });
    // };

    /* deprecated BR-1112 */
    // handleAddressConfirmOne = () => {
    //     const { confirm } = this.state;
    //     confirm.address.one();
    //     this.handleAddressConfirmDialogClose();
    // };

    /* deprecated BR-1112 */
    // handleAddressConfirmAll = () => {
    //     const { confirm } = this.state;
    //     confirm.address.all();
    //     this.handleAddressConfirmDialogClose();
    // };

    /* deprecated BR-1112 */
    // handleAddressReject = async () => {
    //     const { reject } = this.state;
    //     await reject.address();
    //     this.handleAddressConfirmDialogClose();
    // };

    /* deprecated BR-1112 */
    // Profile date and time in period dialogs
    // handleOpenDateTimeConfirmDialog = ({
    //     multiSubscriptions, confirm, reject, type,
    // }) => {
    //     const { showDateTimeConfirmDialog } = this.state;
    //     if (showDateTimeConfirmDialog) return;

    //     this.setState({
    //         showDateTimeConfirmDialog: true,
    //         confirm,
    //         reject,
    //         isDateTimeConfirmDialogExtraText: multiSubscriptions,
    //         dateTimeConfirmDialogType: type,
    //     });
    // };

    /* deprecated BR-1112 */
    // handleCloseDateTimeConfirmDialog = () => {
    //     this.setState({
    //         showDateTimeConfirmDialog: false,

    //         confirm: null,
    //         isDateTimeConfirmDialogExtraText: null,
    //         dateTimeConfirmDialogType: null,
    //     });
    // };

    /* deprecated BR-1112 */
    // handleDateTimeConfirmOne = () => {
    //     const { confirm } = this.state;
    //     confirm.datetime.one();
    //     this.handleCloseDateTimeConfirmDialog();
    // };

    /* deprecated BR-1112 */
    // handleDateTimeConfirmAll = () => {
    //     const { confirm } = this.state;
    //     confirm.datetime.all();
    //     this.handleCloseDateTimeConfirmDialog();
    // };

    /* deprecated BR-1112 */
    // handleDateTimeReject = async () => {
    //     const { reject } = this.state;
    //     await reject.datetime();
    //     this.handleCloseDateTimeConfirmDialog();
    // };

    /* deprecated BR-1112 */
    // handleOpenMobileStreet = (info, options = {}) => {
    //     const { history, location: { search, pathname } } = this.props;
    //     const isProfile = pathname.includes('/profile');

    //     const nextPathname = isProfile ? '/profile/mobile-address/' : '/checkout/mobile-address/';
    //     const prevPath = isProfile ? '/profile/' : '/checkout/';

    //     this.setState({
    //         animateEnterOverlay: true,
    //         allowRenderMobileStreet: true,
    //         mobileStreetInfo: info,
    //         mobileStreetPrevPathname: prevPath,
    //     }, () => {
    //         if (nextPathname === pathname) {
    //             return;
    //         }

    //         const opts = {
    //             pathname: nextPathname,
    //             search,
    //         };
    //         if (options.replace) {
    //             history.replace(opts);
    //         } else {
    //             history.push(opts);
    //         }
    //     });
    // };

    /* deprecated BR-1112 */
    // handleCloseMobileStreet = () => {
    //     const { mobileStreetPrevPathname } = this.state;
    //     const { history, location: { search } } = this.props;
    //     const isAdressCorrect = sessionStorage.getItem('isAddressCorrect');

    //     analyticService.push({
    //         eventName: 'Checkout_Close_Address_Popup',
    //         eventLocation: '/checkout',
    //         result: isAdressCorrect || 'failure',
    //     });
    //     sessionStorage.removeItem('isAddressCorrect');
    //     this.setState({
    //         showMobileStreet: false,
    //         animateExitOverlay: true,
    //     }, () => {
    //         if (history.action === 'PUSH') {
    //             this.handleClickBack();
    //         } else {
    //             history.push(`${mobileStreetPrevPathname}/${search}`);
    //         }
    //     });
    // };

    /* deprecated BR-1112 */
    // onMobileStreetClosed = () => {
    //     this.setState({
    //         showMobileStreet: false,
    //         animateExitOverlay: false,
    //     });
    // };

    handleClickBack = () => {
        const { history } = this.props;
        history.goBack();
    };

    handleOpenMobileDishDetails = (info) => {
        this.setState({
            showMobileDishDetails: true,
            showMobileDishDetailsOverlay: true,
            mobileDishDetailsInfo: info,
        });
    };

    handleCloseMobileDishDetails = () => {
        this.setState({
            showMobileDishDetailsOverlay: false,
        });
        setTimeout(() => (
            this.setState({
                showMobileDishDetails: false,
                mobileDishDetailsInfo: {},
            })
        ), OVERLAY_ANIMATION_TIME);
    };

    // TODO: перенести в AuthContainer
    handleClickQuit = async () => {
        const {
            authData: { onUserQuit },
            userQuery,
            basketQuery,
        } = this.props;

        await onUserQuit();

        try {
            await userQuery.refetch();
            await basketQuery.refetch();
        } catch (e) {
            // window.location.reload();
        }
    };

    async parseSchemaFromUrl() {
        const {
            selectedFilters: { selectedPeriod: period },
            location,
            addItemsToCartByIdList,
            addItemsToCartBySchema,
            basketQuery,
            updateBilling,
            userQuery: { data: { user } },
            isBasketFilled,
        } = this.props;

        const { isBasketFillingInProcess } = this.state;

        if (!period) return;

        const categories = parseCategories(location);
        const dishes = parseDishes(location);
        const isNeededFillBasket = Boolean(categories || dishes);

        const params = queryString.parse(window.location.search);

        if (!isNeededFillBasket) {
            isBasketFilledVar(true);
            return;
        }

        if (isBasketFilled || isBasketFillingInProcess) {
            return;
        }

        this.setState({ isBasketFillingInProcess: true });

        const trialScenario = getTrialScenarioFromUrl();

        if (dishes) {
            await addItemsToCartByIdList({
                variables: {
                    period,
                    trialScenario,
                    dishes,
                },
            });
        } else {
            const menuType = parseMenuType(window.location);
            const tags = parseTags(location);
            await addItemsToCartBySchema({
                variables: {
                    period,
                    menuType,
                    tags: tags || [],
                    schema: categories,
                },
            });
        }

        isBasketFilledVar(true);

        // BR-875 loader [start]
        const isTrialBasket = trialScenario && (trialScenario.includes(SCENARIO_5X2) || trialScenario.includes(SCENARIO_3X2));

        const deliveriesCount = user?.deliveries?.length || 0;
        const isNewUser = deliveriesCount === 0;

        const promocode = params?.promo_code;
        let paymentMethod = params.payment;
        let subscriptionType = params.payment === 'recurrent' ? SUBSCRIPTION_TYPES.subscription : SUBSCRIPTION_TYPES.singlePay;

        // если триальный промокод, то меняется 'paymentMethod' и 'subscriptionType'
        if (isTrialBasket && isNewUser) {
            paymentMethod = 'online';
            subscriptionType = SUBSCRIPTION_TYPES.singlePay;
        }

        promocodeVar(promocode || '');
        subscriptionTypeVar(subscriptionType);

        try {
            await updateBilling({
                variables: {
                    promocode,
                    payment_method: paymentMethod,
                },
            });
            if (basketQuery && basketQuery.refetch) {
                const resp = await basketQuery.refetch({ clear: false });
                const { data: { cart: { typeOfSet } } } = resp;
                this.trackBasketFilling(typeOfSet, true);
            }
        } catch (error) {
            Raven.captureException(error, { extra: { scope: 'parse_schema_refetching_error' } });
        }

        this.setState({ isBasketFillingInProcess: false });
    }

    handleCleanUrlParams = ({ historyChangeType } = { historyChangeType: 'replace' }) => {
        const {
            history,
            location: { pathname, search },
        } = this.props;

        const basketParams = ['dishes', 'expand', 'extra', 'hide', 'cm'];
        const params = queryString.parse(search);
        const cleanParams = omit(params, basketParams);
        const searchString = queryString.stringify(cleanParams);

        const historyChangeFn = history[historyChangeType] || history.replace;

        historyChangeFn({
            pathname,
            search: searchString,
        });
    };

    handleOpenCustomTrialMenu = async () => {
        const {
            updateBilling,
            basketQuery,
        } = this.props;

        const MAKE_NEW_TRIAL_BASKET_PROMO_CODE = 'my5x2';

        promocodeVar(MAKE_NEW_TRIAL_BASKET_PROMO_CODE);
        subscriptionTypeVar('singlePay');

        await updateBilling({
            variables: {
                promocode: MAKE_NEW_TRIAL_BASKET_PROMO_CODE,
                payment_method: 'online',
            },
        });

        this.handleCleanUrlParams({ historyChangeType: 'push' });
        this.handleCloseBasket();

        await basketQuery.refetch();
    };

    trackBasketFilling(typeOfSet, ignoreFlags) {
        const {
            basketQuery,
        } = this.props;

        // TODO: иногда нет ни data ни previousData из BasketQueryAdapter
        if (!basketQuery.cart || !basketQuery.cart.sections) {
            return;
        }

        const params = queryString.parse(window.location.search);
        const subscriptionType = params.payment === 'recurrent' ? SUBSCRIPTION_TYPES.subscription : SUBSCRIPTION_TYPES.singlePay;

        // TODO: check global flag
        const sendEventbyFlags = this.isBasketFilled && !this.isBasketFillingTracked;

        if (sendEventbyFlags || ignoreFlags) {
            const { sections } = basketQuery.cart;
            const dishesDataList = getDishesFromBasketSections(sections);

            const caсhedSourceName = sessionStorage.getItem('cached_source_name');

            analyticService
                .push({
                    eventName: 'add_to_cart',
                    dishes: dishesDataList,
                    source: caсhedSourceName || 'menu',
                    subscriptionType,
                    typeOfSet,
                });

            dishesDataList.forEach((dish) => analyticService.push({
                eventName: 'add_to_cart_fb',
                dish,
            }));

            if (caсhedSourceName) sessionStorage.removeItem('cached_source_name');

            this.isBasketFillingTracked = true;
        }
    }

    renderHeader(overlayIsOpen) {
        const {
            match,
            basketQuery,
            deliveryBasketQuery,
            showDeliveryMenu,
            showMenu,
            showThx,

            mainDishesCountState,
            isSoldOutNotificationVisible,
            onCloseSoldOutNotification,

            updateBilling,
        } = this.props;

        const { sendCode } = queryString.parse(window.location.search);
        const isAuthAfterThx = match.path === '/auth/' && sendCode;
        if (showThx || isAuthAfterThx) return null;

        const {
            isMobileNavigationOpen,
            showFilterChangedAnimation,
            isDishesUpdating,
        } = this.state;

        const headerProps = {

            mainDishesCountState,
            isSoldOutNotificationVisible,
            onClickCloseSoldOutNotification: onCloseSoldOutNotification,

            onClickBack: showDeliveryMenu ? this.handleOpenDeliveryBasket : this.handleClickBack,
            onClickBasketButton: showDeliveryMenu ? this.handleOpenDeliveryBasket : this.handleOpenBasket,
            basketQuery: showDeliveryMenu ? deliveryBasketQuery : basketQuery,
            isDeliveryMenuPage: showDeliveryMenu,
            overlayIsOpen,
            isMobileNavigationOpen,
            isMenuLocation: showMenu || showDeliveryMenu,
            showFilterChangedAnimation,
            onClickQuit: this.handleClickQuit,
            onClickMobileFilters: this.handleOpenMobileFilters,
            onClickMobileWeeks: this.handleOpenMobileWeeks,
            onClickMobileNavigationButton: this.handleToggleMobileNavigation,
            onClickSubscriptionInfoOpen: this.handleOpenSubscriptionInfoPopup,

            setDishesUpdatingState: this.setDishesUpdatingState,
            isCategoryChanging: isDishesUpdating,

            updateBilling,
        };
        // TODO: BR-1084 And here two or three ;))
        return (
            <Header {...headerProps} />
        );
    }

    renderMainContent(overlayIsOpen) {
        const {
            animateEnterOverlay,
            hiddenElements,

            showMobileFilters,
            isMobileFiltersApplying,

            // showAddressConfirmDialog,
            // showDateTimeConfirmDialog,
            showFilterChangedAnimation,
            dishDetailsPathname,
            isDishesUpdating,
        } = this.state;

        const {
            authData: { isAuthed },
            match: {
                params: { profilePage, deliveryId },
                path,
            },
            showMenu,
            showBasket,
            showProfile,
            showThx,
            // showMobileStreet: showMobileStreetOverlay,
            showDeliveryMenu,
            showDeliveryBasket,
            userQuery: { data: { user } },
            deliveryBasketQuery,
            updateDeliveryBasket,
            isUpdatingDeliveryBasket,
            filterQuery,
            onMainDishesLoaded,
        } = this.props;

        const showAuth = path === '/auth/';

        if (showAuth) return null;

        if (showThx) return <Thanks />;

        if (showProfile) {
            return (
                <Profile
                    onClickQuit={this.handleClickQuit}
                    onClickOpenDeliveryBasket={this.handleOpenDeliveryBasket}
                    onClickMobileNavigationButton={this.handleToggleMobileNavigation}

                /* deprecated BR-1112 */
                // isMobileStreetOpen={!!showMobileStreetOverlay}
                // onClickMobileStreet={this.handleOpenMobileStreet}
                // onClickMobileTimeIntervals={this.handleOpenMobileTimeIntervals}
                // isAddressConfirmDialogOpen={showAddressConfirmDialog}
                // onOpenAddressConfirmDialog={this.handleOpenAddressConfirmDialog}
                // isDateTimeConfirmDialogOpen={showDateTimeConfirmDialog}
                // onOpenDateTimeConfirmDialog={this.handleOpenDateTimeConfirmDialog}
                />
            );
        }

        const isMobileMenuDishesVisible = !(showMobileFilters && !isMobileFiltersApplying);

        if (showDeliveryMenu || showDeliveryBasket) {
            if (!isAuthed || !user) return null;
            /*
                FRNT-1362
                try/catch написан для случая, когда пользователь переходит в редактирование меню доставки,
                для которой нет периода в меню блюд (например, закончились продажи на этот цикл)
                В случае ошибки не будет отрисован список блюд, но корзина и header будут доступны.
            */
            const { deliveries } = user;
            const delivery = getDelivery({ deliveries, deliveryId });

            if (delivery && delivery.is_edit_enabled) {
                try {
                    return (
                        <DeliveryMenu
                            filterQuery={filterQuery}
                            overlayIsOpen={overlayIsOpen}
                            updateDeliveryBasket={updateDeliveryBasket}
                            isUpdatingDeliveryBasket={isUpdatingDeliveryBasket}
                            deliveryBasketQuery={deliveryBasketQuery}
                            showMenu={showDeliveryMenu}
                            mobileBigNavIsClose
                            openDishDetails={this.handleOpenDishDetails}
                            openBasket={this.handleOpenDeliveryBasket}
                            isMobileMenuDishesVisible={isMobileMenuDishesVisible}
                            showFilterChangedAnimation={showFilterChangedAnimation}
                            dishDetailsPathname={dishDetailsPathname}
                            setDishesUpdatingState={this.setDishesUpdatingState}
                            isDishesUpdating={isDishesUpdating}
                            onMainDishesRendered={onMainDishesLoaded}
                            setTemporaryBasketPeriod={this.setTemporaryBasketPeriod}
                            onSetPeriod={this.handleSetPeriod}
                        />
                    );
                } catch (e) {
                    Raven.captureException(e, { extra: this.props });
                }
            }
            return null;
        }

        return (
            <Menu
                overlayIsOpen={overlayIsOpen}
                showMenu={showMenu || (showBasket && animateEnterOverlay)}
                openDishDetails={this.handleOpenDishDetails}
                openBasket={this.handleOpenBasket}
                onMainDishesRendered={onMainDishesLoaded}
                hiddenElements={hiddenElements}
                isMobileMenuDishesVisible={isMobileMenuDishesVisible}
                showFilterChangedAnimation={showFilterChangedAnimation}
                onClickSubscriptionInfoOpen={this.handleOpenSubscriptionInfoPopup}
                dishDetailsPathname={dishDetailsPathname}
                setDishesUpdatingState={this.setDishesUpdatingState}
                isDishesUpdating={isDishesUpdating}
                handleCleanUrlParams={this.handleCleanUrlParams}
                setTemporaryBasketPeriod={this.setTemporaryBasketPeriod}
                onSetPeriod={this.handleSetPeriod}
            />
        );
    }

    render() {
        const {
            isMobileNavigationOpen,
            animateEnterOverlay,
            animateExitOverlay,
            showBasket,
            showCheckout,
            // showMobileStreet,
            // allowRenderMobileStreet,
            selectedDish,
            showMobileFilters,
            showMobileFiltersOverlay,
            showMobilePortions,
            mobilePortionsInfo,
            showMobileTimeIntervals,
            mobileTimeIntervalsInfo,
            // mobileStreetInfo,
            showMobileDishDetails,
            showMobileDishDetailsOverlay,
            mobileDishDetailsInfo,
            hiddenElements,
            showDeliveryBasket,
            // isAddressConfirmDialogExtraText,
            // isDateTimeConfirmDialogExtraText,
            // dateTimeConfirmDialogType,
            // showDateTimeConfirmDialog,
            // showAddressConfirmDialog,
            openedModal,
            showModalOverlay,
            showDesinfectionInfoPopup,
            showDesinfectionInfoPopupOverlay,
            dishDetailsPathname,
            // popUpUiState,
        } = this.state;

        const {
            isBasketFilled,
            authData,
            userQuery: { data: { user } },
            selectedFilters,
            match,
            location,
            filterQuery,
            showBasket: showBasketOverlay,
            showCheckout: showCheckoutOverlay,
            // showMobileStreet: showMobileStreetOverlay,
            showDeliveryBasket: showDeliveryBasketOverlay,
            showDeliveryMenu,
            basketQuery,
            deliveryBasketQuery,
            updateDeliveryBasket,
            updateDeliveryDishes,
            isUpdatingDeliveryBasket,
            showDeliveryDishDetails,
            // showPushNotificationPopup,
        } = this.props;

        const { path, params: { dishId, deliveryId } } = match;

        const showDishDetails = showDeliveryDishDetails || Boolean(selectedDish);
        const isDeliveryDishDetails = showDeliveryDishDetails;
        const showDishDetailsOverlay = Boolean(dishId);
        const showAuth = path === '/auth/';
        const showAuthOverlay = path === '/auth/' && isDesktop();
        const overlayIsOpen = (
            showDishDetailsOverlay
            || showBasketOverlay
            || showCheckoutOverlay
            || showAuthOverlay
            // || showMobileStreetOverlay
            || showMobileDishDetailsOverlay
            || showDeliveryBasketOverlay
            || showModalOverlay
            || showDesinfectionInfoPopupOverlay
        ) || false;

        const canCloseCheckout = !hiddenElements.backstep && !path.includes('/pay');

        // TODO: перенести в DishDetails
        let delivery;
        if (user) {
            const deliveries = user.deliveries || [];
            delivery = getDelivery({ deliveries, deliveryId });
        }

        return (
            <>
                <Blur isOpen={overlayIsOpen}>
                    {this.renderHeader(overlayIsOpen)}
                    <Suspense fallback={<div />}>
                        {this.renderMainContent(overlayIsOpen)}
                    </Suspense>
                </Blur>
                {(showBasket) && (
                    <Overlay
                        overlayOpen={showBasketOverlay || showCheckoutOverlay}
                        onClick={canCloseCheckout ? this.handleCloseBasket : () => { }}
                        animateEnter={animateEnterOverlay}
                        animateExit={animateExitOverlay}
                        onExited={this.onBasketClosed}
                        onEntered={this.disableEnterOverlayAnimation}
                        // eslint-disable-next-line arrow-body-style
                        render={(props) => {
                            return (
                                <MenuDishesProvider
                                    menuFilter={filterQuery.menuFilter}
                                    basketQuery={basketQuery}
                                    render={(menuDishesProps) => (
                                        <Basket
                                            {...props}
                                            {...menuDishesProps}
                                            hiddenElements={hiddenElements}
                                            closeBasket={this.handleCloseBasket}
                                            openCheckout={this.handleOpenCheckout}
                                            openDeliveryPricePopup={this.handleOpenDeliveryPricePopup}
                                            onClickMobilePortions={this.handleOpenMobilePortions}
                                            onClickMobileDishDetails={this.handleOpenMobileDishDetails}
                                            onClickSubscriptionInfoOpen={this.handleOpenSubscriptionInfoPopup}
                                            onDishRemoved={this.handleDishRemovedFromBasket}
                                            onClickDesinfectionInfoOpen={this.handleOpenDesinfectionInfoPopup}
                                            dishDetailsPathname={dishDetailsPathname}
                                            canCloseCheckout={canCloseCheckout}
                                            onClickOpenCustomTrialMenu={this.handleOpenCustomTrialMenu}
                                            isShifted={showCheckoutOverlay}
                                        />
                                    )}
                                />
                            );
                        }}
                    />
                )}
                {showCheckout && (
                    <Overlay
                        overlayOpen={showCheckoutOverlay}
                        onClick={canCloseCheckout ? this.handleCloseCheckout : () => { }}
                        animateEnter={animateEnterOverlay}
                        animateExit={animateExitOverlay}
                        onExited={this.onCheckoutClosed}
                        onEntered={this.onCheckoutEntered}
                        render={(props) => {
                            if (!isBasketFilled) return null;
                            return (
                                <CheckoutContainer
                                    {...props}
                                    hiddenElements={hiddenElements}
                                    closeCheckout={this.handleCloseCheckout}
                                    handleOpenDeliveryPricePopup={this.handleOpenDeliveryPricePopup}
                                    // onClickMobileTimeIntervals={this.handleOpenMobileTimeIntervals}
                                    openDeliveryPricePopup={this.handleOpenDeliveryPricePopup}
                                    onClickSubscriptionInfoOpen={this.handleOpenSubscriptionInfoPopup}
                                    handleOpenAddressInfoPopup={this.handleOpenAddressInfoPopup}
                                />
                            );
                        }}
                    />
                )}
                {showDishDetails && (
                    <Overlay
                        overlayOpen={showDishDetailsOverlay}
                        onClick={this.handleCloseDishDetails}
                        animateEnter={animateEnterOverlay}
                        animateExit={animateExitOverlay}
                        onEntered={this.disableEnterOverlayAnimation}
                        onExited={this.onDishDetailsClosed}
                    >
                        <Suspense fallback={<div />}>
                            <DishDetails
                                close={this.handleCloseDishDetails}
                                openBasket={this.handleOpenBasket}
                                dishId={selectedDish}
                                hiddenElements={hiddenElements}
                                isDeliveryDishDetails={isDeliveryDishDetails}
                                delivery={delivery}
                                deliveryBasketQuery={deliveryBasketQuery}
                                updateDeliveryBasket={updateDeliveryBasket}
                            />
                        </Suspense>
                    </Overlay>
                )}
                {showAuth && (
                    <Suspense fallback={<div />}>
                        <Auth
                            authData={authData}
                            isMobileNavigationOpen={isMobileNavigationOpen}
                            handleToggleMobileNavigation={this.handleToggleMobileNavigation}
                        />
                    </Suspense>
                )}
                {showDeliveryBasket && (
                    <Overlay
                        overlayOpen={showDeliveryBasketOverlay}
                        onClick={this.handleCloseDeliveryBasket}
                        animateEnter={animateEnterOverlay}
                        animateExit={animateExitOverlay}
                        onExited={this.onDeliveryBasketClosed}
                        onEntered={this.disableEnterOverlayAnimation}
                        render={(props) => {
                            if (!filterQuery.menuFilter) return null;
                            if (!deliveryBasketQuery.cart) return null;

                            const { period } = queryString.parse(location.search);

                            return (
                                <MenuDishesProvider
                                    selectedPeriod={period}
                                    menuFilter={filterQuery.menuFilter}
                                    basketQuery={deliveryBasketQuery}
                                    render={(menuDishesProps) => (
                                        <Suspense fallback={<MenuSkeletonPage />}>
                                            <DeliveryBasket
                                                {...props}
                                                {...menuDishesProps}
                                                match={match}
                                                updateDeliveryBasket={updateDeliveryBasket}
                                                updateDeliveryDishes={updateDeliveryDishes}
                                                isUpdatingDeliveryBasket={isUpdatingDeliveryBasket}
                                                deliveryBasketQuery={deliveryBasketQuery}
                                                closeBasket={this.handleCloseDeliveryBasket}
                                                onClickMobilePortions={this.handleOpenMobilePortions}
                                                onClickMobileDishDetails={this.handleOpenMobileDishDetails}
                                                onDishRemoved={this.handleDishRemovedFromBasket}
                                                dishDetailsPathname={dishDetailsPathname}
                                                isOpenDeliveryPricePopup={openedModal === 'delivery-info'}
                                                onClickDesinfectionInfoOpen={this.handleOpenDesinfectionInfoPopup}
                                                openDeliveryPricePopup={this.handleOpenDeliveryPricePopup}
                                                onClickSubscriptionInfoOpen={this.handleOpenSubscriptionInfoPopup}
                                            />
                                        </Suspense>
                                    )}
                                />
                            );
                        }}
                    />
                )}
                {showMobileFilters && (
                    <Overlay
                        overlayOpen={showMobileFiltersOverlay}
                        animateEnter
                        animateExit
                        onClick={this.handleCloseMobileFilters}
                        animationDirection="right"
                        render={() => {
                            const basketQueryForFilters = deliveryId ? deliveryBasketQuery : basketQuery;
                            if (!selectedFilters.selectedPeriod || !selectedFilters.selectedCategoryId) return null;
                            if (!basketQueryForFilters.cart) return null;
                            return (
                                <MenuDishesProvider
                                    menuFilter={filterQuery.menuFilter}
                                    basketQuery={basketQueryForFilters}
                                    render={(props) => {
                                        if (!props.hasDataForRenderDishes) return null;
                                        return (
                                            <MobileFilters
                                                {...props}
                                                hiddenElements={hiddenElements}
                                                isCommentAnimationDisabled={showDeliveryMenu}
                                                isNeededRenderPeriod={!showDeliveryMenu}
                                                isNeededRenderSubscription={!showDeliveryMenu}
                                                onClose={this.handleCloseMobileFilters}
                                                onStartApplying={this.handleMobileFiltersStartApplying}
                                            />
                                        );
                                    }}
                                />
                            );
                        }}
                    />
                )}
                {showMobileDishDetails && (
                    <Overlay
                        overlayOpen={showMobileDishDetailsOverlay}
                        animateEnter
                        animateExit
                        onClick={this.handleCloseMobileDishDetails}
                        animationDirection="bottom"
                    >
                        <MobileDishDetails
                            info={mobileDishDetailsInfo}
                            onClose={this.handleCloseMobileDishDetails}
                        />
                    </Overlay>
                )}
                {showDesinfectionInfoPopup && (
                    <Overlay
                        overlayOpen={showDesinfectionInfoPopupOverlay}
                        onClick={this.handleCloseDesinfectionInfoPopup}
                        animateEnter
                        animateExit
                        animationDirection={differentAnimations}
                        render={(props) => (
                            <DesinfectionInfoPopup
                                {...props}
                                isOpenAboveSidebar={showBasket || showDeliveryBasket}
                                onClose={this.handleCloseDesinfectionInfoPopup}
                            />
                        )}
                    />
                )}
                {/* POPUPS LAYER */}
                <MainPopups
                    overlayIsOpen={overlayIsOpen}
                    onClickOpenBasket={this.handleOpenBasket}
                    openedModal={openedModal}
                    showModalOverlay={showModalOverlay}
                    showMobilePortions={showMobilePortions}
                    handleCloseMobilePortions={this.handleCloseMobilePortions}
                    mobilePortionsInfo={mobilePortionsInfo}
                />
            </>
        );
    }
}

Main.propTypes = {
    filterQuery: PropTypes.shape({
        menuFilter: PropTypes.shape({
            periods: PropTypes.arrayOf(PropTypes.shape({
                start: PropTypes.string,
            })),
        }),
    }).isRequired,
    authData: PropTypes.shape({
        isAuthed: PropTypes.bool,
        onUserQuit: PropTypes.func.isRequired,
    }).isRequired,
    userQuery: PropTypes.shape({
        loading: PropTypes.bool,
        error: PropTypes.shape({}),
        data: PropTypes.shape({
            user: PropTypes.shape({
                deliveries: PropTypes.arrayOf(PropTypes.shape({})),
            }),
        }),
        refetch: PropTypes.func,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            dishId: PropTypes.string,
            profilePage: PropTypes.string,
            deliveryId: PropTypes.string,
        }).isRequired,
        path: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string,
        state: PropTypes.shape({
            openBasketFrom: PropTypes.string,
        }),
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
        listen: PropTypes.func,
        push: PropTypes.func,
        goBack: PropTypes.func,
        replace: PropTypes.func,
        action: PropTypes.string,
    }).isRequired,

    selectedFilters: PropTypes.shape({
        selectedCategoryId: PropTypes.string,
        selectedPeriod: PropTypes.string,
        selectedTags: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    basketQuery: PropTypes.shape({
        refetch: PropTypes.func,
        error: PropTypes.shape({}),
        cart: PropTypes.shape({
            typeOfSet: PropTypes.string,
            sections: PropTypes.arrayOf(PropTypes.shape({
                items: PropTypes.arrayOf(PropTypes.shape({})),
            })),
        }),
    }),
    deliveryBasketQuery: PropTypes.shape({
        refetch: PropTypes.func,
    }),
    setDeliveryBasketIsLoading: PropTypes.func.isRequired,
    addItemsToCartByIdList: PropTypes.func.isRequired,
    addItemsToCartBySchema: PropTypes.func.isRequired,
    showMenu: PropTypes.bool,
    showBasket: PropTypes.bool,
    showCheckout: PropTypes.bool,
    // showMobileStreet: PropTypes.bool,
    showThx: PropTypes.bool,
    showProfile: PropTypes.bool,
    showDeliveryMenu: PropTypes.bool,
    showDeliveryBasket: PropTypes.bool,
    showFilters: PropTypes.bool,

    menuDispatchContext: PropTypes.func.isRequired,
    updateDeliveryBasket: PropTypes.func.isRequired,
    updateDeliveryDishes: PropTypes.func.isRequired,
    isUpdatingDeliveryBasket: PropTypes.bool.isRequired,
    showDeliveryDishDetails: PropTypes.bool,
    onUserFetched: PropTypes.func,
    // showPushNotificationPopup: PropTypes.bool.isRequired,
    // onAcceptPushNotifications: PropTypes.func.isRequired,
    // onClosePushNotificationsPopup: PropTypes.func.isRequired,
    mainDishesCountState: PropTypes.string.isRequired,
    isSoldOutNotificationVisible: PropTypes.bool,
    onCloseSoldOutNotification: PropTypes.func.isRequired,
    onMainDishesLoaded: PropTypes.func.isRequired,
    updateBilling: PropTypes.func.isRequired,
    isBasketFilled: PropTypes.bool.isRequired,
};

const EnhancedMain = compose(
    withMenuDispatch,
    withABTestData,
)(Main);

const MainApapter = (props) => {
    const {
        filterQuery,
        userQuery: { data },
        pushNotifications = {},
        showMenu = false,
        basketQuery = {},
        showBasket = false,
        showCheckout = false,
        showThx = false,
        showProfile = false,
        showDeliveryMenu = false,
        showDeliveryBasket = false,
        showFilters = false,
        deliveryBasketQuery = null,
        showDeliveryDishDetails = false,
        onUserFetched = () => { },
        isSoldOutNotificationVisible = false,
    } = props;

    const { openDishDetails } = useDishDetails();

    return (
        <MenuDatesProvider
            userQuery={data}
            filterQuery={filterQuery}
        >
            <LoaderProvider>
                <MenuProvider>
                    <DishesSelectionProvider>
                        <AvailableLinksProvider>
                            <EnhancedMain
                                {...props}
                                pushNotifications={pushNotifications}
                                showMenu={showMenu}
                                basketQuery={basketQuery}
                                showBasket={showBasket}
                                showCheckout={showCheckout}
                                showThx={showThx}
                                showProfile={showProfile}
                                showDeliveryMenu={showDeliveryMenu}
                                showDeliveryBasket={showDeliveryBasket}
                                showFilters={showFilters}
                                deliveryBasketQuery={deliveryBasketQuery}
                                showDeliveryDishDetails={showDeliveryDishDetails}
                                onUserFetched={onUserFetched}
                                isSoldOutNotificationVisible={isSoldOutNotificationVisible}
                            />
                        </AvailableLinksProvider>
                    </DishesSelectionProvider>
                </MenuProvider>
            </LoaderProvider>
        </MenuDatesProvider>
    );
};

MainApapter.propTypes = {
    userQuery: PropTypes.shape({}).isRequired,
    filterQuery: PropTypes.shape({}).isRequired,
};

const MainDAC = (props) => {
    /* DA */
    const authData = useContext(AuthContext);
    const { filterQuery } = useContext(FiltersContext);

    const userQuery = useSuspenseQuery(USER_QUERY, {
        context: {
            message: 'main:init:MainDAC',
        },
        fetchPolicy: 'cache-first',
    });
    const [addItemsToCartByIdList] = useMutation(ADD_ITEMS_TO_CART_BY_ID_LIST, {
        context: {
            message: 'main:mutate:MainDAC',
        },
    });
    const [addItemsToCartBySchema] = useMutation(ADD_ITEMS_TO_CART_BY_SCHEMA, {
        context: {
            message: 'main:mutate:MainDAC',
        },
    });

    const { data: { isBasketFilled } } = useQuery(BASKET_FILLED_STATUS, {
        context: {
            message: 'main:root:MainDAC @client',
        },
    });

    return (
        <MainApapter
            {...props}
            authData={authData}
            filterQuery={filterQuery}
            isBasketFilled={isBasketFilled}
            userQuery={userQuery}
            addItemsToCartByIdList={addItemsToCartByIdList}
            addItemsToCartBySchema={addItemsToCartBySchema}
        />
    );
};

const MainRootContainer = (props) => (
    <Suspense fallback={<MenuSkeletonPage />}>
        <MainDAC {...props} />
    </Suspense>
);

const EnhancedMainAdapter = compose(
    connect,
    withBillingParams,
    withDeliveryBasket,
    withRouter,
)(MainRootContainer);

export default EnhancedMainAdapter;
