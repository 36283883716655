/* eslint-disable max-len */
import React, { useContext, useMemo } from 'react';
import { analyticService } from 'global/services';

import { isStandaloneApp } from 'app/utils/browser';
import { DishesSelectionContext } from 'app/containers/contexts/dishesSelection.context';
import cn from 'classnames';
import { selectedPeriodVar } from 'app/apollo/reaction';
import css from './BasketSetsSelection.module.scss';

import { BasketSetsSelectionSlider } from './BasketSetsSelectionSlider';
import { setsWithFirstPeriodInFuture, upsellHashMap } from './BasketSetsSelection.record';

export type TCurrentSetType = 'hp' | 'ny' | 'romantic' | 'maslenitsa' | 'green' | 'marchgifts' | 'march' | 'easter'

interface IBasketSetsSelectionProps {
    setName: TCurrentSetType
}

interface INavigationWrapperProps {
    children: React.ReactNode,
    setName: TCurrentSetType,
}

const LinkWrapper = ({ children, setName }: INavigationWrapperProps) => {
    const isApp = isStandaloneApp();
    // @ts-expect-error
    const { href } = upsellHashMap.get(setName);
    const link = setsWithFirstPeriodInFuture.includes(setName)
        ? href
        : `${href}?period=${selectedPeriodVar()}`;
    return (
        <a
            href={link}
            target={!isApp ? '_blank' : undefined}
            rel="noreferrer"
            className={css.basketSetsSelectionRootButton}
        >
            {children}
        </a>
    );
};

const ButtonWrapper = ({ children, setName }: INavigationWrapperProps) => {
    const { onOpenSelection } = useContext<any>(DishesSelectionContext);
    // @ts-expect-error
    const { tag } = upsellHashMap.get(setName);

    return (
        <button
            onClick={() => { onOpenSelection({ tag, targetRealm: 'new_upsell' }); }}
            type="button"
            className={css.basketSetsSelectionRootButton}
        >
            {children}
        </button>
    );
};

export const BasketSetsSelection = (props: IBasketSetsSelectionProps) => {
    const {
        setName,
    } = props;
    // @ts-expect-error
    const { type, title, description } = upsellHashMap.get(setName);

    const NavigationWrapper = useMemo(
        () => (type === 'link' ? LinkWrapper : ButtonWrapper),
        [type],
    );

    const onClickHandler = () => {
        analyticService.push({
            eventName: 'Upsell_Click',
            upsell_type: 'promo',
            banner_name: setName,
            source_name: 'basket',
        });
    };

    const backgroundClasses = cn({
        [css.basketSetsSelectionRoot]: true,
        [css[setName]]: true,
    });

    const titleClasses = cn({
        [css.basketSetsSelectionTitle]: true,
        [css[setName]]: true,
    });

    const descriptionClasses = cn({
        [css.basketSetsSelectionDescription]: true,
        [css[setName]]: true,
    });

    return (
        <NavigationWrapper setName={setName}>
            <div
                className={css.basketSetsSelectionRootWrapper}
                onClickCapture={onClickHandler}
                role="none"
            >
                <div className={backgroundClasses}>
                    <div className={css.basketSetsSelectionSliderContainer}>
                        <div className={titleClasses}>
                            {title}
                        </div>
                        <div className={descriptionClasses}>
                            {description}
                        </div>
                        <div className={css.basketHiddenBlock} />
                        <BasketSetsSelectionSlider setName={setName} />
                    </div>
                </div>
            </div>
        </NavigationWrapper>
    );
};

export default BasketSetsSelection;
