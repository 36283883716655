import React from 'react';
import PropTypes from 'prop-types';
import {
    useMutation,
} from '@apollo/client';
import queryString from 'query-string';
import cookie from 'js-cookie';
import flatten from 'lodash/flatten';
import Raven from 'raven-js';

import { analyticService, errorService } from 'global/services';

import { PUSH_LEAD_V2 } from 'app/graphql/network/lead';
import { getReferrerCode, removeReferrerCode } from 'app/utils/referrer';
import { getPlatform } from 'app/utils/leadPlatform';
import { getUTMParams } from '../utils/utmParams';


const SURVEY_TYPE_LOGIN = 16; // checkoutv4_login
const SURVEY_TYPE_PHONE = 14; // checkoutv4_phone


const getBasketDishesIds = (cart) => {
    const dishes = flatten(cart.sections.map((s) => s.items));
    return dishes.map(({ dish_id: dishId }) => Number(dishId));
};

const getPushLeadVariables = (
    {
        phone,
        location = 'checkout',
        email = null,
        date,
    },
    props,
) => {
    const {
        history: { location: { search } },
        userQuery,
        basketQuery,
        promocode: { promocode },
        paymentMethod: { paymentMethod },
    } = props;

    const user = (userQuery && userQuery.user) || { name: '' };
    const cart = (basketQuery && basketQuery.cart) || {
        sections: [],
        totals: {
            total_discount_price: 0,
        },
    };

    const query = queryString.parse(search);

    const sourceSurvey = query.source || '';
    const categories = cart.sections.map((s) => Number(s.id));
    const referrerCode = getReferrerCode();
    const menuType = query.menu;

    const roistatVisit = cookie.get('roistat_visit');

    const utm = getUTMParams();

    const subscription = {
        dishes: getBasketDishesIds(cart),
        promocode,
        price: {
            total: cart.totals.total_discount_price,
        },
        payment: paymentMethod,
        profile: { referrer_code: referrerCode },
        categories,
        types: menuType,
    };
    return {
        date,
        site_version: 2,
        email,
        phone,
        name: user.name,
        source: {
            survey: sourceSurvey,
            type: location === 'popup' ? SURVEY_TYPE_LOGIN : SURVEY_TYPE_PHONE,
        },
        utm,
        location,
        ga_client_id: cookie.get('_ga_cid'),
        admitad_uid: cookie.get('_aid'),
        partner_id: query.partner,
        roistat_visit: roistatVisit,
        subscription,
        platform: getPlatform(),
    };
};

const checkIfPlatformCorrect = (props) => {
    const appType = getAppType();
    const osObj = getMobileOperatingSystem();
    const os = osObj.ios ? 'ios' : 'android';
    const platform = props?.platform || '';


    if (!platform.includes(os) || !platform.includes(appType)) {
        const GOOGLE_PLAY_APP_NAME_REGEX = /ru.elementare/;
        const IOS_APP_COOKIE_REGEX = /app_name=ios_app/;

        errorService.log({
            text: 'Что-то не то platform',
            source: 'client',
            extra: {
                appType,
                osObj,
                pushLeadPlaform: props?.platform,
                platform: navigator?.userAgentData?.platform || navigator?.platform || 'unknown',
                userAgent: navigator?.userAgent || navigator?.vendor || window?.opera,
                savedAppType: sessionStorage?.getItem('appType'),
                _android_pwa_home: window?.matchMedia('(display-mode: standalone)')?.matches,
                _android_pwa_g_play: GOOGLE_PLAY_APP_NAME_REGEX?.test(document?.referrer),
                _android_wpa: window.matchMedia('(display-mode: standalone)')?.matches,
                _ios_legacy_app: navigator?.userAgent?.includes('Elementaree'),
                _ios_native_handler: Boolean(window?.webkit && window?.webkit?.messageHandlers && window?.webkit.messageHandlers?.analyticsMessageHandler),
                _ios_cookie: IOS_APP_COOKIE_REGEX?.test(document?.cookie),

            },
        });
    }
};


// TODO: можно заменить на кастомный хук
// TODO: явно получать зависимости, а не из коннекта
function PushLead(props) {
    const {
        Child,
        ...restProps
    } = props;

    const [pushLeadMutation] = useMutation(PUSH_LEAD_V2);

    const pushLead = async ({ variables }) => {
        const {
            location,
        } = variables;

        const allVariables = getPushLeadVariables(variables, props);


        try {
            checkIfPlatformCorrect(allVariables);

            const resp = await pushLeadMutation({
                variables: { ...allVariables },
            });

            const { pushLead: leadData } = resp.data;

            const eventData = {
                ...leadData,
                eventName: 'Lead_Sent',
                phone: allVariables.phone,
                email: allVariables.email,
                lead_type: location === 'popup' ? SURVEY_TYPE_LOGIN : SURVEY_TYPE_PHONE,
            };

            analyticService.push(eventData);

            if (leadData.isFirstLead) {
                analyticService.push({
                    ...eventData,
                    eventName: 'Lead_Sent_First',
                });
            }

            removeReferrerCode();

            return resp;
        } catch (e) {
            Raven.captureException(e);
            return e;
        }
    };

    return (
        <Child
            {...restProps}
            pushLead={pushLead}
        />
    );
}

PushLead.propTypes = {
    Child: PropTypes.func.isRequired,
};


const withPushLead = (WrappedComponent) => (
    (props) => (
        <PushLead
            {...props}
            Child={WrappedComponent}
        />
    )
);

export default withPushLead;
