/* eslint-disable no-restricted-syntax */
type TQueryParamKeys = 'promo_code' | 'period' | 'payment'

/**
 * @param {Object} queryParams <TQueryParamKeys, string>
 * @description Ставит значния query params в URL
 */
export function URLUpdater(props: Record<TQueryParamKeys, string>) {
    const params = new URLSearchParams(window.location.search);
    for (const [key, value] of Object.entries(props)) {
        if (!value) {
            params.delete(key);
        } else {
            params.set(key, value);
        }
    }
    window.history.replaceState(null, '', `?${params.toString()}${window.location.hash}`);
}
