import React from 'react';

interface IUpsellItem {
    type: 'link' | 'button',
    title: React.ReactElement
    description: React.ReactElement
    href?: string,
    tag?: string,
}

// const halloweenUpsell: IUpsellItem = {
//     type: 'button',
//     tag: ' halloween',
//     title: (
//         <>
//             Страшно вкусный бокс на Хэллоуин
//         </>
//     ),
//     description: (
//         <>
//             Настоящая вечеринка всего за час — ужасно вкусные блюда, паутины, летучие мыши и, конечно, тыквенная голова Джека.
//         </>
//     ),
// };

const maslenitsaUpsell: IUpsellItem = {
    type: 'button',
    tag: 'maslenitsa',
    title: (
        <>
            Меню на Масленицу
        </>
    ),
    description: (
        <>
            Сытные или сладкие, на завтрак
            <br />
            или на ужин, с готовыми блинами или с самодельными — мы подготовили все варианты наборов,
            чтобы вы смогли устроить настоящую масленичную неделю за считанные минуты!
        </>
    ),
};

const greenUpsell: IUpsellItem = {
    type: 'button',
    tag: '1220',
    title: (
        <>
            Лёгкий подход
            <br />
            к лёгкой еде
        </>
    ),
    description: (
        <>
            <li>Много белка и овощей</li>
            <li>Баланс БЖУ на 400-500 ккал</li>
            <li>Новые интересные продукты!</li>
        </>
    ),
};

const romanticUpsell: IUpsellItem = {
    type: 'link',
    href: '/romantic/',
    title: (
        <>
            Время для свиданий дома
        </>
    ),
    description: (
        <>
            Всё для романтического вечера: от рецептов и продуктов до салфеток-сердечек для сервировки и тем для душевных разговоров.
        </>
    ),
};

const marchgiftsUpsell: IUpsellItem = {
    type: 'link',
    href: '/marchgifts/',
    title: (
        <>
            Подарочный бокс
            <br />
            на 8 марта
        </>
    ),
    description: (
        <>
            Набор с несъедобными ингредиентами и пошаговым рецептом для полного релакса
        </>
    ),
};

const marchsUpsell: IUpsellItem = {
    type: 'link',
    href: '/march/',
    title: (
        <>
            Великие женщины
        </>
    ),
    description: (
        <>
            Посвящаем эти блюда выдающимся женщинам 20 века. Пусть они вдохновят на великие дела и вас!
        </>
    ),
};

const easterUpsell: IUpsellItem = {
    type: 'link',
    href: '/easterbox/',
    title: (
        <>
            Празднуем Пасху
        </>
    ),
    description: (
        <>
            Привезем всё необходимое,
            чтобы испечь роскошные куличи, покрасить яйца натуральными красителями, накрыть праздничный стол.
        </>
    ),
};

export const setsWithFirstPeriodInFuture = ['easter'];

export const upsellHashMap = new Map([
    ['maslenitsa', maslenitsaUpsell],
    ['romantic', romanticUpsell],
    ['marchgifts', marchgiftsUpsell],
    ['green', greenUpsell],
    ['march', marchsUpsell],
    ['easter', easterUpsell],
]);
